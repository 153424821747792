<template lang="pug">
  .conversion__inner
    .conversion__container
      .conversion__info
        h2.title.conversion__title ПЕРЕОСНАЩЕННЯ STARLINK ДЛЯ АВТОМОБІЛІВ
        .text.conversion__text
          p Drivelink - це нова оболонка терміналу супутникового звʼязку Starlink у аеродинамічному корпусі, що фіксується магнітами до корпусу Вашого авто та надає швидкісний доступу до Інтернету з будь-якої точки світу.
      ul.conversion__list
        li.conversion__item.conversion__item--civil
          .conversion__img.conversion__img--civil
            img(
              :src="AutoImg"
              alt="auto"
            )
          h3.title.conversion__subtitle
            span DRIVELINK  для цивільних
        li.conversion__item
          .conversion__img
            img(
              :src="MilitaryImg"
              alt="solider"
            )
          h3.title.conversion__subtitle
            span DRIVELINK  для військових
          a.link.conversion__link.conversion__link--civil(href="http://zsu.drivelink.com.ua") Перейти на сайт
</template>

<script>
import MilitaryImg from '@/assets/images/conversion/01.jpg'
import AutoImg from '@/assets/images/conversion/02.jpg'

export default {
  name: 'ConversionBlock',
  data () {
    return {
      MilitaryImg,
      AutoImg
    }
  }
}
</script>
